import React from 'react';

import Layout from './../components/Layout';
import Seo from './../components/Seo';
import FaqItem from './../components/FaqItem/FaqItem';
import faqStyles from './../styles/faq.module.scss';
import faqData from './../common/faq-data';

const Faq = () => {

  const items = faqData.map((faq, index) => {
    return (
      <FaqItem key={faq.title} data={faq} index={index}/>
    );
  });

  return (
    <Layout>
      <Seo title={'FAQ | TechPods'}/>
      <main>
        <section className={faqStyles.faq}>
          <h1 className="primary-title">FAQs</h1>
          {items}
        </section>
      </main>
    </Layout>
  );
};

export default Faq;