import React, { useState } from 'react';

import faqItemStyles from './faq-item.module.scss';

const FaqItem = ({ data, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleItem = () => setIsOpen(isOpen ? false : true);

  return (
    <div
      className={faqItemStyles.item}
      onClick={toggleItem} 
      onKeyDown={toggleItem}
      role='button'
      tabIndex={index}
    >
      <div>
        <h2>{data.question}</h2>
        <div>
        <svg id="arrow" width="20" height="13" viewBox="0 0 20 13" fill="none" className={isOpen && faqItemStyles.activeArrow}>
          <path d="M2.7325 0.600586L10 7.85225L17.2675 0.600586L19.5 2.83309L10 12.3331L0.5 2.83309L2.7325 0.600586Z" fill="#7192B1"/>
        </svg>
        </div>
      </div>
      <p className={isOpen ? faqItemStyles.active : faqItemStyles.closed}>{data.answer}</p>
    </div>
  );
};

export default FaqItem;