const faqData = [
  {
    question: 'What is Co-sourcing?',
    answer: 'Co-sourcing is a strategic, and sustainable type of outsourcing which enables organisations to mitigate risk, and reduce costs while retaining full control of the whole process. This sustainable, long-term collaboration model is characterised by a high degree of trust, close cooperation, increased strategic alignment, shared risks, shared value and greater knowledge exchange, as both parties share a mutually vested interest in the outcome of the partnership. '
  },
  {
    question: 'Why would my company choose TechPods over a traditional outsourcing company?',
    answer: 'With TechPods, you get full control of your team structure, from recruitment to daily workload management, which improves predictability of work quality & timelines, and overall team performance.'
  },
  {
    question: 'When should our company consider using TechPods?',
    answer: 'TechPods helps companies of all sizes (from startups, to scaleups, to stock market listed companies) scale their technology capacity during different stages of their life cycle. You should consider using TechPods if you want full control of your technology functions, predictable, fixed costs which you can fully control, authentic knowledge about performance, corporate knowledge retention within your company, and a partner who will contribute to your long-term success. '
  },
  {
    question: 'How much does it really cost?',
    answer: 'The TechPods pricing model is fully transparent, consisting of employee’s salary, and TechPods’ overheads meaning you know exactly how much it costs for us to employ a person working from our office. We cannot predict how much a person’s salary will be because it depends on seniority, tech stack etc, however a mid-level JS developer with 2 years of commercial experience will cost approximately €1,500 PCM. '
  },
  {
    question: 'What kind of technologies are popular in Bulgaria?',
    answer: 'Bulgaria, like any other tech hub in the world, follows global technology trends, meaning the same technology skill sets can be found in Bulgaria as the UK or USA. For a full list of popular technologies in Bulgaria, please see our services section. '
  },
  {
    question: 'How long does it take to hire a software engineer?',
    answer: 'Recruitment lead times vary depending on seniority, and tech stack requirements. TechPods has a strong position in the local market, and a constantly growing talent pipeline, meaning you will have immediate access to CV’s, however placing candidates could take between 3-6 weeks.'
  },
  {
    question: 'Who legally employs our remote team?',
    answer: 'TechPods mitigates risk by fulfilling all employment requirements, including employment agreements, social benefits, health insurances, and taxes. Your company has no employment liabilities. '
  }
];

export default faqData;